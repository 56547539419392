import { BASE_BSC_SCAN_URLS, BASE_URL, defaultNetwork, networkName } from '../config'
import { nodes } from './getRpcUrl'

export const setupNetwork = async ({ library }) => {
    const provider = library.currentProvider || window.ethereum
    console.log(provider)
    if (provider) {
        const chainId = defaultNetwork
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
            });
            return true
        } catch (error) {
            console.log(error)
            console.log(`switch error: ${error}`)
            if (error.code === 4902 || error.code === "-32603") {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${chainId.toString(16)}`,
                                chainName: networkName,
                                nativeCurrency: {
                                    name: 'POL',
                                    symbol: 'POL',
                                    decimals: 18,
                                },
                                rpcUrls: nodes,
                                blockExplorerUrls: [`${BASE_BSC_SCAN_URLS}/`],
                            },
                        ],
                    })
                    return true
                } catch(err) {
                    return false
                }
            }
            console.error('Failed to setup the network in Metamask:', error)
            return false
        }
    } else {
        console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
        return false
    }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress, tokenSymbol, tokenDecimals, img=`${BASE_URL}/images/logo-icon.png`) => {
    const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20',
            options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
                image: img,
            },
        },
    })

    return tokenAdded
}